import { Component, OnInit } from '@angular/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { ApplicationService } from 'app/service/ticket-editor/application.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';

@Component({
  selector: 'ticket-manager',
  templateUrl: './ticket-manager.component.html',
  styleUrls: ['./ticket-manager.component.scss']
})
export class TicketManagerComponent implements OnInit {
  /**
   * Constant
   */
  Constant = Constant;

  /**
   * tab selected
   */
  tabSelected: number = Constant.SALES_RECORD_ENUM;

  listApp: ApplicationDTO[] = []; // list Application
  isRoot: boolean;

  constructor(
    private dataService: DataService,
    private ticketService: TicketEditorService,
    private ticketManagerService: TicketManagerService,
    private applicationService: ApplicationService,
    private commonService: CommonService
  ) {
    this.isRoot = this.commonService.getCommonObject().userIdString == Constant.ROOT;
  }

  ngOnInit(): void {
    this.ticketService.getInformationAccount().subscribe(async data => {
      await this.getAllApplication();
      this.ticketManagerService.setInformationAccount(data);
      this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, true]);
    });
  }

  /**
   * choose tab
   * @param tabEnum
   */
  public chooseTab(tabEnum: any): void {
    if (this.tabSelected == tabEnum) {
      return;
    }
    this.tabSelected = tabEnum;
    switch (this.tabSelected) {
      case Constant.RESERVATION_TM_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, true]);
        this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, false]);
        this.dataService.sendData([Constant.IS_TAB_REPORT, false]);
        break;
      }
      case Constant.REPORT_ENUM: {
        this.dataService.sendData([Constant.IS_TAB_REPORT, true]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, false]);
        this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, false]);
        break;
      }
      default: {
        this.dataService.sendData([Constant.IS_TAB_SALES_RECORD, true]);
        this.dataService.sendData([Constant.IS_TAB_REPORT, false]);
        this.dataService.sendData([Constant.IS_TAB_RESERVATION, false]);
        break;
      }
    }
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.applicationService.findAllApplication().subscribe(res => {
        // this.listApp = Helper.convertResApplication(res).filter(e => e.appId);
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }
}
