import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME, SortTypeEnum } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogWebRefundTicketComponent } from 'app/dialog/dialog-web-refund-ticket/dialog-web-refund-ticket.component';
import { Common } from 'app/model/entity/common';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { Orders } from 'app/model/entity/ticket-editor/orders';
import { CommonService } from 'app/service/common.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';
import { DialogService } from '../../../service/dialog.service';
import { Order } from './../../../model/entity/ticket-editor/order';

import { Store } from '@ngrx/store';
import { DialogCustomSortComponent } from 'app/dialog/dialog-custom-sort/dialog-custom-sort.component';
import { IHash, OptionFilter } from 'app/model/entity/sort-filter-object';
import { DataService } from 'app/service/data.service';
import { AppState } from 'app/store/app.state';

@Component({
  selector: 'tab-sales-record',
  templateUrl: './tab-sales-record.component.html',
  styleUrls: ['./tab-sales-record.component.scss']
})
export class TabSalesRecordComponent implements OnInit, OnDestroy {
  @Input() tabSelected: number; // tab selected
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;

  Helper = Helper;
  @ViewChild('dataContainer', { static: false }) dataContainer: ElementRef;
  mapFilterOption = new Map();
  /**
   * language key
   */
  languageKey: string;
  /**
   * Config for datepicker
   */
  config: any;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  placeholderSearch: string; // Set text placeholder input search
  headerColumns: any[] = [
    { headerName: 'ticket-manager.sales-record.order-ID', property: 'orderId' },
    { headerName: 'ticket-manager.sales-record.sale-ticket-id', property: 'saleTicketId' },
    { headerName: 'ticket-manager.sales-record.ticket-type', property: 'ticketType' },
    { headerName: 'ticket-manager.sales-record.ticket-name', property: 'ticketName' },
    { headerName: 'ticket-manager.sales-record.company-name', property: 'companyName' },
    { headerName: 'ticket-manager.sales-record.app-ID', property: 'appName' },
    { headerName: 'ticket-manager.sales-record.user-ID', property: 'userId' },
    { headerName: 'ticket-manager.sales-record.purchased-at', property: 'purchasedAt' },
    { headerName: 'ticket-manager.sales-record.status-refund', property: 'status' },
    { headerName: 'ticket-manager.sales-record.sales-amount', property: 'salesAmount' }
  ]; // List header ticket filter
  tickets = []; // List ticket filter
  // Common object
  private commonObject: Common;
  /**
   * Column data for ticket information
   */
  columns = [
    'orderId',
    'saleTicketId',
    'ticketType',
    'ticketName',
    'companyName',
    'appName',
    'userId',
    'purchasedAt',
    'status',
    'salesAmount'
  ];
  listTicketType = [];
  listAppID = [];
  isSearching = false; // Status searching
  dataFilter = []; // List ticket after filter
  /**
   * Data for search sales ticket
   */
  dataSearch = {
    dateFrom: '',
    dateTo: '',
    ticketType: '',
    orderId: '',
    userId: ''
  };
  orderSelected: Orders;
  orderDetailSelected: Order;
  comboItemSelected: any;
  dataFilterOrder: Orders[];
  isSortFilter: boolean;
  isCheckAllOptionFilter: boolean;
  ordersDisplay: any[];
  columnSortFiltering: string;
  isShowPopUpSortFilter: boolean;
  lastColumnFilter: string;
  listFilterDisplay: Array<OptionFilter>;
  listFilterDisplayOrigin: Array<OptionFilter>;
  isFilter: boolean;
  isClear: boolean;
  listCurrentFilter: IHash = {};
  listSorted: any = [];

  headerColumnOrderDetail1: any = [
    {
      label: 'ticket-manager.sales-record.order-ID',
      field: 'orderId'
    },
    {
      label: 'ticket-manager.sales-record.sale-ticket-id',
      field: 'saleTicketId'
    },
    {
      label: 'ticket-manager.sales-record.ticket-type',
      field: 'ticketType'
    },
    {
      label: 'ticket-manager.sales-record.ticket-name',
      field: 'ticketName'
    },
    {
      label: 'ticket-manager.sales-record.company-name',
      field: 'companyName'
    },
    {
      label: 'ticket-manager.sales-record.app-ID',
      field: 'appName'
    },
    {
      label: 'ticket-manager.sales-record.user-ID',
      field: 'userId'
    },
    {
      label: 'ticket-manager.sales-record.payment-id',
      field: 'paymentId'
    },
    {
      label: 'ticket-manager.sales-record.purchased-at',
      field: 'purchasedAt'
    },
    {
      label: 'ticket-manager.sales-record.status-refund',
      field: 'status'
    },
    {
      label: 'ticket-manager.sales-record.sale-amount',
      field: 'salesAmount'
    },
    {
      label: 'ticket-manager.sales-record.price-adult',
      field: 'priceAdult'
    }
  ];
  headerColumnOrderDetail2: any = [
    {
      label: 'ticket-manager.sales-record.refund-deadline-at',
      field: 'refundDeadlineAt'
    },
    {
      label: 'ticket-manager.sales-record.refunded-at',
      field: 'refundedAt'
    }
  ];
  readonly LAST_FILTER = 'lastFilter';
  readonly IS_FILTER = 'isFilter';

  @Input() listApp: ApplicationDTO[]; // list Application
  appIdSelected: string;
  constructor(
    private translateService: TranslateService,
    private ticketManagerService: TicketManagerService,
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private commonService: CommonService,
    public readonly store: Store<AppState>,
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {
    // Translate title
    this.placeholderSearch = this.translateService.instant('ticket-manager.sales-record.search');
    // Action menu
    this.subscriptions.push(
      // Export list ticket
      this.menuActionService.actionExportTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          // Nothing
        }
      }),
      // Refund web
      this.menuActionService.actionRefundTicket.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          this.webRefund();
        }
      }),
      // Clear setting
      this.menuActionService.actionClearSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          this.clearSearch();
        }
      }),
      //Sort and filter
      this.menuActionService.actionSortFilter.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketManagerComponent] && this.tabSelected == Constant.SALES_RECORD_ENUM) {
          this.sortAndFilter();
        }
      }),
      // Get language
      this.translateService.onLangChange.subscribe(() => {
        // Translate title
        this.placeholderSearch = this.translateService.instant('ticket-manager.sales-record.search');
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.config = {
          showWeekNumbers: false,
          format: 'YYYY-MM-DD',
          firstDayOfWeek: 'mo',
          unSelectOnClick: false,
          locale: Helper.getLocale(this.languageKey)
        };
        if (this.ordersDisplay && this.ordersDisplay.length) {
          this.ordersDisplay = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));
        }
      })
    );
    this.commonObject = this.commonService.getCommonObject();
    this.isSortFilter = false;
    this.headerColumns.forEach(e => {
      e.isFilterBy = '';
      e.isSortBy = '';
    });
    this.dataService.currentData.subscribe(data => {
      if (data && data[0] == Constant.REFUND) {
        this.searchTicket(data[1]);
      }
    });
  }

  ngOnDestroy(): void {
    this.dataFilterOrder = undefined;
    this.orderSelected = undefined;
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    this.config = {
      showWeekNumbers: false,
      format: 'YYYY-MM-DD',
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey)
    };
  }

  /**
   * open date picker
   * @param picker
   * @param time
   */
  openDatePicker(picker: DatePickerDirective, time: any): void {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * Search ticket
   * @param item
   */
  searchTicket(orderId?: string): void {
    this.isSearching = true;
    // Clear search
    if (
      !this.dataSearch['ticketType'] &&
      !this.dataSearch['dateFrom'] &&
      !this.dataSearch['dateTo'] &&
      !this.dataSearch['orderId'] &&
      !this.dataSearch['userId'] &&
      (!this.appIdSelected || this.appIdSelected == '-1')
    ) {
      this.clearSearch();
      return;
    }
    if (this.dataFilterOrder && this.dataFilterOrder.length) {
      this.headerColumns.forEach(e => this.clearFilter(e.property));
    }
    this.resetColumnsSort();
    let payload = {
      orderId: this.dataSearch['orderId'],
      ticketType: this.dataSearch['ticketType'],
      appId: this.appIdSelected ? (this.appIdSelected == '-1' ? '' : this.appIdSelected) : '',
      startDate: this.dataSearch['dateFrom'] ?? '',
      endDate: this.dataSearch['dateTo'] ?? '',
      userId: this.dataSearch['userId'] ?? ''
    };
    this.ticketManagerService.getOrders(payload).subscribe(
      data => {
        this.dataFilterOrder = data;
        this.ordersDisplay = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));
        this.cdr.detectChanges();
        if (!this.dataFilterOrder || this.dataFilterOrder.length == 0) {
          this.orderDetailSelected = undefined;
          this.comboItemSelected = undefined;
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('ticket-manager.sales-record.no-data')
            }
          });
          return;
        }
        if (!orderId) {
          this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
          let div = document.getElementById('dataTotalSale');
          div.scrollTop = 0;
        } else {
          this.selectOrder(
            this.dataFilterOrder.find(e => e.orderId == orderId),
            null
          );
        }
      },
      error => {
        this.dataFilterOrder = new Array<Orders>();
        this.ordersDisplay = new Array<any>();
        this.orderDetailSelected = undefined;
        this.comboItemSelected = undefined;
        this.orderSelected = undefined;
      }
    );
  }

  /**
   * Web refund
   */
  webRefund(): void {
    if (!this.dataFilterOrder || !this.orderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-manager.sales-record.choose-ticket')
        }
      });
      return;
    }
    // Show dialog web refund
    this.dialogService.showDialog(
      DialogWebRefundTicketComponent,
      {
        data: {
          listOrderId: this.orderSelected.orderId,
          salesAmount: this.orderSelected.salesAmount
        }
      },
      result => {}
    );
  }

  /**
   * Clear search
   */
  clearSearch() {
    this.isSearching = false;
    this.dataSearch['dateFrom'] = undefined;
    this.dataSearch['dateTo'] = undefined;
    this.dataSearch['ticketType'] = '';
    this.dataSearch['orderId'] = '';
    this.dataSearch['userId'] = '';
    this.appIdSelected = '-1';
    if (this.dataFilterOrder && this.dataFilterOrder.length) {
      this.headerColumns.forEach(e => this.clearFilter(e.property));
    }
    this.isClear = false;
    this.ordersDisplay = undefined;
    this.dataFilterOrder = undefined;
    this.orderSelected = undefined;
    this.orderDetailSelected = undefined;
    this.comboItemSelected = undefined;
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.listFilterDisplay = undefined;
    this.listFilterDisplayOrigin = undefined;
    this.isCheckAllOptionFilter = undefined;
    this.isSortFilter = false;
    this.columnSortFiltering = '';
    this.listSorted = [];
    this.resetColumnsSort();
    this.mapFilterOption.clear();
  }

  /**
   *sortAndFilter
   */
  sortAndFilter() {
    this.isSortFilter = !this.isSortFilter;
    this.mapFilterOption = new Map();
    if (!this.ordersDisplay || !this.ordersDisplay.length) {
      return;
    }
    if (!this.isSortFilter) {
      this.resetSortFilter();
      if (this.dataFilterOrder && this.dataFilterOrder.length) {
        this.headerColumns.forEach(e => this.clearFilter(e.property));
        this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
      }
    }
  }

  /**
   * resetSortFilter
   */
  resetSortFilter() {
    this.ordersDisplay = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));
    this.resetColumnsSort();
    this.listSorted = [];
    this.listCurrentFilter = {};
    this.lastColumnFilter = undefined;
    this.isFilter = undefined;
    this.columnSortFiltering = undefined;
  }

  /**
   * selectOrder
   * @param order
   * @param e
   * @returns
   */
  selectOrder(order: Orders, e: any): void {
    if (e?.target?.id === 'checkBoxOrder') {
      return;
    }
    this.orderSelected = order;
    this.ticketManagerService.getOrder(this.orderSelected.orderId).subscribe(data => {
      this.orderSelected.orderDetail = data;
      this.orderDetailSelected = this.orderSelected.orderDetail;
      this.comboItemSelected = data?.comboItems;
      this.dataContainer.nativeElement.scrollTop = 0;
    });
  }

  /**
   * changeChecked
   * @param idOrder
   * @param e
   */
  // changeChecked(idOrder: string, e): void {
  //   e.stopPropagation();
  //   const index = this.dataFilterOrder.findIndex(element => element.orderId == idOrder);
  //   this.dataFilterOrder[index].isChecked = !this.dataFilterOrder[index].isChecked;
  // }

  /**
   * getNameTicketType
   * @param ticketType
   * @returns
   */
  getNameTicketType(ticketType: string) {
    if (ticketType == Constant.SPECIAL_TICKET_ENUM) {
      return this.translateService.instant('ticket-manager.sales-record.tickets-according-to-plan');
    } else if (ticketType == Constant.COUPON_ENUM) {
      return this.translateService.instant('ticket-manager.sales-record.number-of-tickets');
    } else {
      return this.translateService.instant('ticket-manager.sales-record.set-ticket');
    }
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplay(value: String): String {
    let temp = _.cloneDeep(value).toString();
    if (temp?.split('W')?.length > 10 && temp.length > 21) {
      value = value.substring(0, 21) + '...';
    } else if (value.length > 36) {
      value = value.substring(0, 36) + '...';
    }
    return value;
  }

  /**
   * displayScheduleAt
   * @param time
   * @returns
   */
  displayTime(time: string) {
    if (!time) {
      return '';
    }
    return time.substring(0, 19).replace('T', ' ');
  }

  /**
   * getTicketType
   * @param type
   * @returns
   */
  getTicketType(type: string) {
    if (!type) {
      return;
    }
    switch (type) {
      case 'combo':
        return 'セット券';
      case 'ticket':
        return '企画券';
      case 'coupon':
        return '綴り券';
      default:
        break;
    }
  }

  /**
   * getStatus
   * @param status
   * @returns
   */
  getStatus(status: string) {
    if (!status) {
      return;
    }
    switch (status) {
      // case '購入中':
      //   return 'pending';
      // case '購入済み':
      //   return 'bought';
      // case '購入失敗':
      //   return 'failed';
      // case '払戻済み':
      //   return 'refunded';
      // case 'セッションタイムアウト':
      //   return 'sessionExpired';
      case '購入済':
        return '購入';
      case '通常払戻済':
        return '払戻';
      default:
        return status;
    }
  }

  /**
   * getStatusForComboItem
   * @param status
   * @returns
   */
  getStatusForComboItem(status: string) {
    if (!status) {
      return;
    }
    switch (status) {
      case '未使用':
        return 'bought';
      case '有効化済み':
        return 'activated';
      case '使用済み':
        return 'used';
      default:
        return status;
    }
  }

  /**
   *showPopupSortFilter
   * @param title
   * @param event
   */
  showPopupSortFilter(property: string, event) {
    event.stopPropagation();
    this.isShowPopUpSortFilter = !this.isShowPopUpSortFilter;
    // if is show
    if (this.isShowPopUpSortFilter) {
      this.columnSortFiltering = property;
      this.fetchFilterData(property);
    }
  }

  /**
   * fetch data filter to pop up
   * @param property column show popup
   */
  public fetchFilterData(property: string): void {
    // let listDataTableGetOptionFilter = this.dataTablesDisplay.filter(
    //   data => !data[this.LAST_FILTER] || data[this.LAST_FILTER] === property
    // );
    let isFiltered = false;
    //namhocgioi code
    let listFilterTmp = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));

    let listFilterInProperty = [];
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == property) {
        isFiltered = true;
        listFilterInProperty = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
        continue;
      }
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      // filter = filter.map(e=> e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    let listDataTableGetOptionFilter = _.cloneDeep(listFilterTmp);

    let listDataTableOptionFilter: any[] = this.getUniqueOption(listDataTableGetOptionFilter, property);

    // if not last column filter
    if (this.lastColumnFilter !== property) {
      this.listFilterDisplay = [];
      for (let i = 0; i < listDataTableOptionFilter.length; i++) {
        //get list option filter
        this.listFilterDisplay[i] = {
          isChecked: listFilterInProperty.length == 0 ? true : listFilterInProperty.includes(listDataTableOptionFilter[i][property]),
          // isChecked: !listDataTableOptionFilter[i][this.IS_FILTER],
          name: listDataTableOptionFilter[i][property]
        };
      }
      // if is last column filter
    } else {
      this.listFilterDisplay = this.listCurrentFilter[property];
      // update if add
      listDataTableOptionFilter.forEach(dataTable => {
        if (!this.listFilterDisplay.find(optionFilter => optionFilter.name === dataTable[property])) {
          this.listFilterDisplay.push({
            isChecked: !isFiltered,
            name: dataTable[property]
          });
        }
      });
      // remove old value
      this.listFilterDisplay?.forEach(option => {
        if (option.isChecked && !listDataTableOptionFilter.find(dataTable => dataTable[property] === option.name)) {
          this.listFilterDisplay = this.listFilterDisplay.filter(data => data.name !== option.name);
        }
      });
    }
    this.listFilterDisplay = _.sortBy(this.listFilterDisplay, ['name']);
    this.mapFilterOption.set(property, this.listFilterDisplay);
    // get list memorize checked
    this.listFilterDisplayOrigin = _.cloneDeep(this.listFilterDisplay);
    this.controlCheckBoxCheckAllFilter();
  }

  /**
   * sort basic
   * @param property property sorted
   * @param type type sort
   */
  public sortProperty(property: string, type: string): void {
    this.listSorted = [[property], [type]];
    this.ordersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
    let div = document.getElementById('dataTotalSale');
    div.scrollTop = 0;
    this.isShowPopUpSortFilter = false;
    // remove all sort of all column
    this.resetColumnsSort();
    // set columns is sorting
    let indexColumnSort = this.headerColumns.findIndex(data => data.property === property);
    this.headerColumns[indexColumnSort].isSortBy = type;
    this.headerColumns[indexColumnSort][Constant.IS_CHOSEN] = true;
  }

  /**
   * reset column sort disable in list
   */
  private resetColumnsSort(): void {
    this.headerColumns.forEach(column => {
      column[Constant.IS_CHOSEN] = false;
      column.isSortBy = Constant.EMPTY;
    });
  }

  /**
   * clear filter
   * @param property name of column clear filter
   */
  public clearFilter(property: string): void {
    if (this.mapFilterOption.has(property)) {
      this.mapFilterOption.delete(property);
    }
    this.headerColumns.find(data => data.property == property).isFilterBy = Constant.EMPTY;
    this.isFilter = false;
    // set all option in list is true
    this.listCurrentFilter[property]?.forEach(element => {
      element.isChecked = true;
    });
    // if is last column filter
    if (property === this.lastColumnFilter) {
      this.isClear = true;
      this.listFilterDisplayOrigin.forEach(data => (data.isChecked = true));
      if (this.isSearching) {
        this.filterOder(property, false);
      }
      // if is not last column filter
    } else {
      let keys = Object.keys(this.listCurrentFilter);
      let nextProperTyFilter = keys[keys.indexOf(property) + 1];
      // set lastFilter is next column filter
      this.dataFilterOrder?.forEach(element => {
        if (element[this.LAST_FILTER] === property) {
          element[this.IS_FILTER] = false;
          element[this.LAST_FILTER] = nextProperTyFilter;
        }
      });
      // get new list option filter for next property filter in listCurrentFilter
      let listTimetableGetOptionFilter = this.dataFilterOrder?.filter(
        data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
      );
      let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
      let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
      for (let i = 0; i < listTimetableOptionFilter.length; i++) {
        listOptionFilterNew[i] = {
          isChecked: !listTimetableOptionFilter[i].lastFilter,
          name: listTimetableOptionFilter[i][nextProperTyFilter]
        };
      }
      listOptionFilterNew.forEach(element => {
        for (let j = 0; j < this.listCurrentFilter[nextProperTyFilter]?.length; j++) {
          if (element.name === this.listCurrentFilter[nextProperTyFilter][j].name) {
            element.isChecked = this.listCurrentFilter[nextProperTyFilter][j].isChecked;
          }
        }
      });
      // set new list option filter for next property filter in listCurrentFilter
      this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
      this.isShowPopUpSortFilter = false;
      delete this.listCurrentFilter[property];
      this.controlCheckBoxCheckAllFilter();
    }
  }

  /**
   * control checkBox check all filter when uncheck and checked
   */
  private controlCheckBoxCheckAllFilter(): void {
    this.isCheckAllOptionFilter = this.listFilterDisplayOrigin?.every(filter => filter.isChecked);
  }

  /***
   * getUniqueOption
   */
  public getUniqueOption = (array, property): any => {
    return _.uniqBy(array, property);
  };

  /**
   * showCustomSort
   */
  showCustomSort() {
    this.isShowPopUpSortFilter = false;

    // replace template with display 1, display 2
    let propertySorts = _.cloneDeep(this.headerColumns);
    propertySorts.forEach(e => {
      e.headerName = this.translateService.instant(e.headerName);
    });
    // propertySorts[propertySorts.length - 2].headerName = this.translateService.instant('timetable-editor.display-1');
    // if (this.isDisplay2) {
    //   propertySorts[propertySorts.length - 1].headerName = this.translateService.instant('timetable-editor.display-2');
    // } else {
    //   propertySorts.pop();
    // }
    // show dialog custom sort
    this.dialogService.showDialog(DialogCustomSortComponent, { data: { list: [this.listSorted, propertySorts] } }, result => {
      if (result) {
        this.listSorted = result;
        for (let i = 0; i < this.headerColumns.length; i++) {
          let index = this.listSorted[0].findIndex(column => column === this.headerColumns[i]?.property);
          if (index === -1) {
            this.headerColumns[i].isSortBy = Constant.EMPTY;
          } else {
            this.headerColumns[i].isSortBy = this.listSorted[1][index];
          }
        }

        // sort
        this.ordersDisplay = this.convertListOrder(_.cloneDeep(this.dataFilterOrder?.filter(data => !data[this.LAST_FILTER])));
        this.ordersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
        this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
        this.updateColumnCustomSort(this.headerColumns, propertySorts);
      }
    });
  }

  /**
   * set up for disable option in custom sort
   *
   * @param columnsBeforeSort
   * @param columnsAfterSort
   */
  private updateColumnCustomSort(columnsBeforeSort: any, columnsAfterSort: any): void {
    columnsAfterSort?.forEach((columnAfter, index) => {
      columnsBeforeSort[index][Constant.IS_CHOSEN] = columnAfter[Constant.IS_CHOSEN];
    });
  }

  /**
   * check select all option
   */
  public checkAllOptionFilter(): void {
    this.isCheckAllOptionFilter = !this.isCheckAllOptionFilter;
    this.listFilterDisplayOrigin.forEach(option => {
      option.isChecked = this.isCheckAllOptionFilter;
    });
    this.listFilterDisplay = [...this.listFilterDisplayOrigin];
  }

  /**
   * change checked
   * @param index index of option filter
   */
  public checkOptionFilter(index: number): void {
    this.listFilterDisplayOrigin[index].isChecked = !this.listFilterDisplayOrigin[index].isChecked;
    // this.controlCheckBoxCheckAllFilter();
  }

  /**
   * filterOder
   * @param property
   * @param isFilterFirstTime
   * @returns
   */
  filterOder(property: string, isFilterFirstTime: boolean) {
    // do not filter all
    if (this.listFilterDisplayOrigin.every(data => !data.isChecked)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    if (this.mapFilterOption.has(property) && _.isEqual(this.mapFilterOption.get(property), this.listFilterDisplayOrigin)) {
      this.isShowPopUpSortFilter = false;
      return;
    }
    this.isFilter = true;
    this.headerColumns.find(data => data.property === property).isFilterBy = property;
    this.lastColumnFilter = property;
    let columnsFiltered = Object.keys(this.listCurrentFilter);
    // if is not clear last column filter
    if (!this.isClear) {
      this.listFilterDisplay = [...this.listFilterDisplayOrigin];
    }
    // if list option filter checked all or clear last column filter
    if (this.listFilterDisplay.findIndex(data => !data.isChecked) === -1) {
      delete this.listCurrentFilter[property];
      columnsFiltered = Object.keys(this.listCurrentFilter);
      this.lastColumnFilter = columnsFiltered[columnsFiltered.length - 1];
      this.isClear = false;
      this.isFilter = false;
      this.headerColumns.find(data => data.property === property).isFilterBy = Constant.EMPTY;
      // if filter a column was filtered
    } else {
      if (this.listCurrentFilter[property] && this.lastColumnFilter !== columnsFiltered[columnsFiltered.length - 1]) {
        let nextProperTyFilter = columnsFiltered[columnsFiltered.indexOf(property) + 1];
        this.dataFilterOrder?.forEach(element => {
          if (element[this.LAST_FILTER] === property) {
            element[this.LAST_FILTER] = nextProperTyFilter;
          }
        });
        let listTimetableGetOptionFilter = this.dataFilterOrder?.filter(
          data => data[this.LAST_FILTER] === nextProperTyFilter || !data[this.LAST_FILTER]
        );
        listTimetableGetOptionFilter.sort((a, b) => {
          // Kiểm tra xem a có thuộc tính lastFilter không
          const hasLastFilterA = a.hasOwnProperty('lastFilter') && a['lastFilter'] != undefined;
          // Kiểm tra xem b có thuộc tính lastFilter không
          const hasLastFilterB = b.hasOwnProperty('lastFilter') && b['lastFilter'] != undefined;

          // So sánh và đưa các phần tử không có lastFilter lên đầu
          if (!hasLastFilterA && hasLastFilterB) {
            return -1;
          } else if (hasLastFilterA && !hasLastFilterB) {
            return 1;
          } else {
            return 0;
          }
        });
        let listTimetableOptionFilter = this.getUniqueOption(listTimetableGetOptionFilter, nextProperTyFilter);
        let listOptionFilterNew: Array<OptionFilter> = new Array<OptionFilter>();
        for (let i = 0; i < listTimetableOptionFilter.length; i++) {
          listOptionFilterNew[i] = {
            isChecked: !listTimetableOptionFilter[i].lastFilter,
            name: listTimetableOptionFilter[i][nextProperTyFilter]
          };
        }
        this.listCurrentFilter[nextProperTyFilter] = listOptionFilterNew;
        delete this.listCurrentFilter[property];
      }
      // set list option filter property
      this.listCurrentFilter[property] = this.listFilterDisplay;
    }
    this.getCurrentFilter(this.listFilterDisplay, property);
    // get list Timetable show up on screen
    // this.dataFilterOrder?.filter(data => data[this.LAST_FILTER]).map(data => (data[Constant.IS_SELECTED] = false));
    //namhocgioi code
    let listFilterTmp = this.convertListOrder(_.cloneDeep(this.dataFilterOrder));
    for (let filterTmp in this.listCurrentFilter) {
      if (filterTmp == 'undefined') continue;
      let filter = this.listCurrentFilter[filterTmp].filter(e => e.isChecked == true)?.map(e => e.name);
      listFilterTmp = listFilterTmp.filter(e => filter.includes(e[filterTmp]));
    }
    //
    this.ordersDisplay = listFilterTmp;
    this.ordersDisplay.sort(this.dynamicSortMultiple(this.listSorted));
    this.isShowPopUpSortFilter = false;

    this.controlCheckBoxCheckAllFilter();
    if (!isFilterFirstTime) {
      if (this.ordersDisplay?.length) {
        this.selectOrder(this.ordersDisplay[Constant.FIRST_ELEMENT_INDEX], null);
        this.cdr.detectChanges();
        let div = document.getElementById('dataTotalSale');
        div.scrollTop = 0;
      } else {
        this.orderSelected = undefined;
        this.orderDetailSelected = undefined;
        this.comboItemSelected = undefined;
      }
    }
  }

  /**
   * set lastFilter for Timetable to filter or un filter
   * @param currentFilter list option filter property
   * @param property column filtering
   */
  public getCurrentFilter(currentFilter: OptionFilter[], property: string): void {
    for (let i = 0; i < currentFilter.length; i++) {
      if (!currentFilter[i].isChecked) {
        let arr = this.dataFilterOrder?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          element[this.IS_FILTER] = true;
          if (!element[this.LAST_FILTER]) {
            element[this.LAST_FILTER] = property;
          }
        });
      } else {
        let arr = this.dataFilterOrder?.filter(data => data[property] == currentFilter[i].name);
        arr.forEach(element => {
          if (element[this.LAST_FILTER] == property) {
            element[this.IS_FILTER] = false;
            element[this.LAST_FILTER] = undefined;
          }
        });
      }
    }
  }

  /**
   * sort multiple
   * @param dataSort list properties and sort type sorted
   */
  public dynamicSortMultiple(dataSort: any): any {
    return function(object1, object2) {
      let output = 0,
        i = 0;
      while (output == 0 && i < dataSort[0]?.length) {
        let value1 = object1[dataSort[0][i]] ?? Constant.EMPTY; // dataSort[0] is list column sorted
        let value2 = object2[dataSort[0][i]] ?? Constant.EMPTY;
        if (dataSort[1][i] === SortTypeEnum.DESC) {
          // dataSort[1] is list sort type corresponding to column
          output = value1 > value2 ? -1 : value1 < value2 ? 1 : 0;
        } else {
          output = value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        }
        i++;
      }
      return output;
    };
  }

  checkObjectType(input) {
    return typeof input == 'object';
  }

  /**
   * convertListOrder
   * @param listFilterTmp
   * @returns
   */
  convertListOrder(listFilterTmp): any {
    listFilterTmp.forEach(e => {
      for (let property in e) {
        if (this.checkObjectType(e[property])) {
          e[property] = this.languageKey == 'en' ? e[property]?.en : e[property]?.ja;
        }
      }
      if (e['ticketType']) {
        e['ticketType'] = this.languageKey != 'en' ? this.getTicketType(e['ticketType']) : e['ticketType'];
      }
    });
    return listFilterTmp;
  }

  /**
   * getTitle
   * @returns
   */
  public getTitle(): string {
    return this.listApp?.find(app => app.appId == this.appIdSelected)?.name;
  }
}

export interface itemObject {
  key: string;
  value: any;
}
