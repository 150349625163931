import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { Common } from 'app/model/entity/common';
import { ContentDayReservationManager } from 'app/model/entity/ticket-manager/content-day-reservation-manager';
import { CommonService } from 'app/service/common.service';
import { AppState } from 'app/store/app.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'tab-reservation',
  templateUrl: './tab-reservation.component.html',
  styleUrls: ['./tab-reservation.component.scss']
})
export class TabReservationComponent implements OnInit {
  /**
   * constant
   */
  Constant = Constant;

  /**
   * month selected
   */
  selectedMonth: any;

  isPreviousMonth: boolean;

  /**
   * true if > finish month
   */
  isNextMonth: boolean;

  /**
   * year selected
   */
  selectedYear: number;

  /**
   * common object
   */
  commonObject: Common;

  /**
   * current date
   */
  currentDate: Date = new Date();

  /**
   * content days month
   */
  contentDaysMonth: Array<ContentDayReservationManager>;

  /**
   * calendars
   */
  calendarsInTwoYear: Array<ContentDayReservationManager>;

  /**
   * list month
   */
  listMonth: Array<{ value: string; key: number }> = Array(
    { value: this.translateService.instant('schedule-merge.month-1'), key: 0 },
    { value: this.translateService.instant('schedule-merge.month-2'), key: 1 },
    { value: this.translateService.instant('schedule-merge.month-3'), key: 2 },
    { value: this.translateService.instant('schedule-merge.month-4'), key: 3 },
    { value: this.translateService.instant('schedule-merge.month-5'), key: 4 },
    { value: this.translateService.instant('schedule-merge.month-6'), key: 5 },
    { value: this.translateService.instant('schedule-merge.month-7'), key: 6 },
    { value: this.translateService.instant('schedule-merge.month-8'), key: 7 },
    { value: this.translateService.instant('schedule-merge.month-9'), key: 8 },
    { value: this.translateService.instant('schedule-merge.month-10'), key: 9 },
    { value: this.translateService.instant('schedule-merge.month-11'), key: 10 },
    { value: this.translateService.instant('schedule-merge.month-12'), key: 11 }
  );

  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();

  /**
   * return value selects the time table
   */

  selectTable: string = 'monthly';

  /**
   * returns true if you choose to display details by month
   */

  isShowDetailByMonth: boolean = false;

  /**
   * returns true if you choose to display details by week
   */

  isShowDetailByWeek: boolean = false;
  constructor(private commonService: CommonService, private translateService: TranslateService, public readonly store: Store<AppState>) {
    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multiple language column header
        this.listMonth = [
          { value: this.translateService.instant('schedule-merge.month-1'), key: 0 },
          { value: this.translateService.instant('schedule-merge.month-2'), key: 1 },
          { value: this.translateService.instant('schedule-merge.month-3'), key: 2 },
          { value: this.translateService.instant('schedule-merge.month-4'), key: 3 },
          { value: this.translateService.instant('schedule-merge.month-5'), key: 4 },
          { value: this.translateService.instant('schedule-merge.month-6'), key: 5 },
          { value: this.translateService.instant('schedule-merge.month-7'), key: 6 },
          { value: this.translateService.instant('schedule-merge.month-8'), key: 7 },
          { value: this.translateService.instant('schedule-merge.month-9'), key: 8 },
          { value: this.translateService.instant('schedule-merge.month-10'), key: 9 },
          { value: this.translateService.instant('schedule-merge.month-11'), key: 10 },
          { value: this.translateService.instant('schedule-merge.month-12'), key: 11 }
        ];
      })
    );
    this.subscriptions.push(this.store.select(state => state).subscribe());
    this.commonObject = this.commonService.getCommonObject();
    this.currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject);
  }

  ngOnInit(): void {
    if (!this.selectedMonth) {
      this.currentDate = new Date();
      this.selectedYear = this.currentDate.getFullYear();
      this.selectedMonth = this.currentDate.getMonth();
    }
    this.calendarsInTwoYear = Helper.getCalendarsTicketFromCurrentDate();
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
      this.calendarsInTwoYear,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
    this.contentDaysMonth[19].color = '#94abd4';
    this.contentDaysMonth[20].color = 'rgba(255, 0, 0, 0.5)';
    this.contentDaysMonth[19].text = 'ABC';
    this.contentDaysMonth[20].text = 'XYZß';
  }

  showPrevMonth(): void {}

  showNextMonth(): void {}

  /**
   * select month
   * @param month
   */
  selectMonth(month: any): void {
    this.selectedMonth = +month;
    // get calendar by selected month and selected year
    this.contentDaysMonth = Helper.getCalendarsByMonthYearSchedule(
      this.calendarsInTwoYear,
      this.selectedMonth,
      this.selectedYear,
      this.commonObject
    );
  }

  /**
   * change display mode
   * @param time
   */
  changeDisplayMode(time: any): void {
    this.selectTable = time;
  }

  /**
   * show Detail by Month
   */
  showDetailByMonth(): void {
    this.isShowDetailByMonth = !this.isShowDetailByMonth;
  }

  /**
   * show Detail by week
   */
  showDetailByWeek(): void {
    this.isShowDetailByWeek = !this.isShowDetailByWeek;
  }
}
